import React from "react"
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps"

const geoUrl =
  "/countries-10m.json"

export default function MapChart({plants}) {

    function compileMarkers() 
    {
        let markers = [];
        if(plants)
        {
            for(const plant of plants)   
            {
                let color = "#0F0";
                if(plant.alarms.length > 0)
                {
                    color = "#F00";
                }
                let m = { markerOffsetX: plant.markeroffsetx, markerOffsetY: plant.markeroffsety, name: plant.description, coordinates: [plant.longitude, plant.latitude], color: color }
                markers.push(m);
            }
            console.log("Markers!");
            console.log(markers)
        }        
        return markers;
    }

  return (
    <ComposableMap projection="geoMercator" projectionConfig={{
        rotate: [-5.0, -51.0, 0],
        scale: 6000
      }}>
      <Geographies geography={geoUrl}>
      {({ geographies, outline, borders }) => (
          <>
            <Geography geography={outline} fill="#0088b0" />
            <Geography geography={borders} fill="none" stroke="#D6D6DA" />
          </>
        )}
      </Geographies>
      {compileMarkers().map(({ name, coordinates, markerOffsetX, markerOffsetY, color }) => (
        <Marker key={name} coordinates={coordinates}>
          <circle r={4} fill={color} stroke="#fff" strokeWidth={2} />
          <text
            textAnchor="middle"
            x={markerOffsetX}
            y={markerOffsetY}
            style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
          >
            {name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  )
}
